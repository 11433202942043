export const PeerCallStatus = {
  InitialState: "InitialState",
  Connecting: "Connecting",
  Connected: "Connected",
};

export const EndpointType = {
  phone_number: { description: "phone_number", icon: "fas fa-hashtag" },
  agent: { description: "agent", icon: "fas fa-user" },
  queue: { description: "queue", icon: "fas fa-layer-group" },
};

export const ContactFilter = {
  SMS: "sms",
  EMAIL: "email",
  TASK: "task",
  VOICE: "voice",
  PHONE: "phone",
  CUSTOMTASK: "custom_task",
  PHONE_NUMBERS: "phoneNumber",
  AGENTS_QUEUES: "agentsQueues",
  QUICK_CONNECTS: "quickConnects",
};

export const AGENT_TO_AGENT = {
  ACTIVITY_TYPE: {
    ON_NET: "on-net",
  },
};
export const Engage = {
  Channel: {
    VOICE: "voice",
    SETUP_VOICE_CALL: "setupVoiceCall",
  },
  InitiationMethod: {
    OUTBOUND: "OUTBOUND",
    INBOUND: "INBOUND",
    MISSED: "MISSED",
    REJECTED: "REJECTED",
  },
};

export const ChatItemType = "ATTACHMENT";

export const UserStatus = {
  Hold: "Hold",
  Busy: "Busy",
};

export const videoCallContants = {
  MIC: "mic",
  AUDIO: "audio",
  VIDEO: "video",
  SHARE: "share",
  DEFAULTTILE: 1,
  INITIATOR: "initiator",
  RESOURCE_NAME: "AWS Chime video call integration",
};

export const oneClickSetup = {
  placeholder: "Email Setup Wizard",
  name: "1-Click-Email",
  type: "email",
  provider: "Amazon-SES/1-Click",
  description: "Webhook for one click email setup.",
  success: "Success",
  pending: "Pending",
  failed: "Failed",
  external: "(External Domain)",
  outbound: "Outbound",
  inbound: "Inbound",
  devDomain: "emailreceiverdev.com",
  prodDomain: "emailreceiving.com",
  dextr: "dextr",
};

export const providers = {
  twilio: "twilio",
  amazonSes: "amazon-ses",
  oneClickSes: "Amazon-SES/1-Click",
  messageMedia: "message-media",
};

export const DynamicsWizard = {
  get: "GET",
  post: "POST",
  patch: "PATCH",
  contactInitiated: "Contact Initiated",
  openUrl: "Open URL for Agent",
  contactCleared: "Contact Cleared",
  recordingsReady: "Recordings Ready",
  contactInitiatedResponse: "STEP2-Response",
  contactInitiatedResponseSecond: "STEP3-Response",
  openUrlResponse: "STEP4-Response",
  contactClearedResponse: "STEP5-Response",
  recordingsReadyResponse: "STEP6-Response",
  contactInitiatedStepOne: 2,
  contactInitiatedStepTwo: 3,
};

export const SalesforceWizard = {
  autolaunched: "Autolaunched",
  platform: "Platform",
  contactInitiated: "Contact Initiated",
  openUrl: "Open URL for Agent",
  contactCleared: "Contact Cleared",
  recordingsReady: "Recordings Ready",
  contactInitiatedResponse: "STEP1-Response",
  contactClearedResponse: "STEP2-Response",
  recordingsReadyResponse: "STEP3-Response",
  provider: "Salesforce",
  salesforceIntegrationType: "Salesforce Integration",
};

export const Permissions = {
  routing: "Routing",
  hoursOfOperations: "Hours of operations",
  phoneNumber: "Phone Number",
  communicationAndPresence: "Communication and Presence",
  teamStatus: "Team Status",
  announcements: "Announcements",
  raiseHand: "Raise Hand",
  directory: "Directory",
  silentMonitor: "Silent Monitor",
  changeOutboundQueue: "Change Outbound Queue",
  toggleRecording: "Toggle Recording",
  inlineHistory: "Inline History",
  smartReplies: "Smart Replies",
  usersAndPermissions: "Users and Permissions",
  users: "Users",
  securityProfiles: "Security Profiles",
  scripts: "Scripts",
  postCallSurveys: "Post Call Surveys",
  integration: "Integration",
  customLinks: "Custom Links",
  general: "General",
  channel: "Channel",
  reporting: "Reporting",
  routingProfileSelf: "Routing profile - self",
  globalContact: "Global Contact",
  activity: "Activity",
  newActivityFlag: "New Activity",
  oldActivityFlag: "Legacy Activity",
  ownActivity: "Own Activity",
  allActivity: "All Activity",
  sameQueue: "Same Queue",
  qualityAnalytics: "Quality/Analytics",
  recordings: "Recordings",
  transcripts: "Transcripts",
  analytics: "Analytics",
  dashboard: "Dashboard",
  dashboardFlag: "Home tab",
  agentPerformance: "Agent performance",
  recentActivity: "Recent Activity",
  averageTimes: "Average times",
  agentAvailability: "Agent Availability",
  contacts: "Contacts",
  contactsInQueue: "Contacts in Queue",
  agentPerformancePermission: "Agent Performance Permission",
  ownPerformance: "Own Performance",
  allPerformance: "All Performance",
  sameQueuePerformance: "Performance of Agents in My Queues",
  channels: "Channels",
  video: "Video",
  inLineHistory: "Inline History",
};

export const backupFileExpirationDays = 5;
export const AttributeType = "EARLY_MEDIA";

export const HISTORICAL_REPORTS_V2 = {
  COLUMNS: {
    NAME: "Name",
    ACCESS: "Access",
    TYPE: "Type",
  },
  CHECKBOX: {
    ACTIVE: "Active",
    INACTIVE: "Inactive",
  },
};

const AWS_IFRAMES = {
  AMAZON_Q: {
    LABEL: "Amazon Q",
    NAME: "wisdom",
    PERMISSION: "Wisdom.View",
    URL: "connect/wisdom-v2/",
  },
  CASES: {
    LABEL: "engage.caseFlow",
    NAME: "cases",
    PERMISSION: "Cases.View",
    URL: "connect/cases/agent-app/",
  },
  CUSTOMER_PROFILES: {
    LABEL: "engage.customerProfiles",
    NAME: "customerprofiles",
    PERMISSION: "CustomerProfiles.View",
    URL: "connect/customerprofiles-v2/",
  },
  STARGATE: {
    LABEL: "engage.guidedFlow",
    NAME: "stargate",
    PERMISSION: "CustomViews.Access",
    URL: "connect/stargate/app",
  },
};

export const ACTIVITY_DETAIL = {
  EMAIL: "email",
  CHAT: "chat",
  SMS: "sms",
  ANNOUNCEMENT: "announcement",
  TASK: "task",
  VOICE: "voice",
  VOICEMAIL: "voicemail",
  HISTORY: "history",
  TRANSCRIPT: "transcript",
  TASK_DETAILS: "task-details",
  RECORDING: "recording",
  OUT: "OUT",
  FWD: "FWD",
  IN: "IN",
};

const CONNECTPATH_TABS = {
  CONTACT_DETAILS: {
    LABEL: "engage.contactDetails",
    NAME: "details",
  },
};

export const ENGAGE_TABS = {
  CASES: AWS_IFRAMES.CASES,
  CUSTOMER_PROFILES: AWS_IFRAMES.CUSTOMER_PROFILES,
  CONTACT_DETAILS: CONNECTPATH_TABS.CONTACT_DETAILS,
  STARGATE: AWS_IFRAMES.STARGATE,
  AMAZON_Q: AWS_IFRAMES.AMAZON_Q,
};

export const WebhookBasedTimer = 120;
export const SIZES = ["Bytes", "KB", "MB", "GB", "TB"];

export const NETWORK_RESPONSE_CONSTANTS = {
  GET: "GET",
  PUT: "PUT",
  MB: "MB",
  SUCCESS: 200,
  ERROR: 400,
};

export const EMAIL_ATTACHMENT_LIMT = 24.99;
export const RE_DEPLOY = "reDeploy";
export const CONTACT_STATES = {
  DISCONNECTED: "disconnected",
  CONNECTED: "connected",
  CONNECTED_ON_HOLD: "CONNECTED_ONHOLD",
  CONNECTING: "connecting",
  MISSED: "missed",
  ENDED: "ended",
  ERROR: "error",
  REJECTED: "rejected",
};

export const MULTI_LEVEL_SERIES_STATE = {
  ACTIVE: "ACTIVE",
  INACTIVE: "INACTIVE",
};

export const IS_ENABLED_GO_TO_APPLICATION = "IsEnabledGoToApplication";
export const ABILITY_ACTIONS = {
  CREATE: "create",
  DOWNLOAD: "download",
  INBOUND: "inbound",
  OUTBOUND: "outbound",
  LISTEN: "listen",
  SHARE: "share",
  VIEW: "view",
  ALL: "all",
  MANAGE: "manage",
  EDIT: "edit",
  UPDATE: "update",
  ENABLE: "enable",
  REMOVE: "remove",
  DELETE: "delete",
};

export const ABILITY_SUBJECTS = {
  NEW_ACTIVITY_FLAG: "new_activity_flag",
  OLD_ACTIVITY_FLAG: "old_activity_flag",
  OWN_ACTIVITY: "own_activity",
  OTHERS_ACTIVITY: "others_activity",
  SAME_QUEUE: "same_queue",
  VOICE: "voice",
  CHAT: "chat",
  SOCIAL: "social",
  SMS: "sms",
  VIDEO: "video",
  EMAIL: "email",
  DASHBOARD_FLAG: "dashboard_flag",
  MY_PERFORMANCE: "my_performance",
  MY_RECENT_ACTIVITY: "my_recent_activity",
  RECENT_ACTIVITY: "recent_activity",
  WAIT_TIME: "wait_time",
  AGENT_AVAILABILITY: "agent_availability",
  CONTACTS: "contacts",
  CONTACTS_IN_QUEUE: "contacts_in_queue",
  RECORDINGS: "recordings",
  TRANSCRIPTS: "transcripts",
  ANALYTICS: "analytics",
  HOURS_OF_OPERATIONS: "hours_of_operations",
  TEAM_STATUS: "team_status",
  ANNOUNCEMENTS: "announcements",
  RAISE_HAND: "raise_hand",
  DIRECTORY: "directory",
  SILENT_MONITOR: "silent_monitor",
  CHANGE_OUTBOUND_QUEUE: "change_outbound_queue",
  TOGGLE_RECORDING: "toggle_recording",
  INLINE_HISTORY: "inline_history",
  SMART_REPLIES: "smart_replies",
  USERS: "users",
  SECURITY_PROFILES: "security_profiles",
  INTEGRATION: "integration",
  CUSTOMLINKS: "customLinks",
  GENERAL: "general",
  CHANNEL: "channel",
  REPORTING: "reporting",
  GLOBAL_CONTACT: "global_contact",
  SCRIPTS: "scripts",
  POST_CALL_SURVEYS: "post_call_surveys",
  OWN_PERFORMANCE: "own_performance",
  OTHERS_PERFORMANCE: "others_performance",
  SAME_QUEUE_PERFORMANCE: "same_queue_performance",
  INTERNAL_TRANSFER: "INTERNAL-TRANSFER",
};

export const ABILITY_VALUES = {
  View: null,
  All: null,
  Create: null,
  Download: null,
  Edit: null,
  Enable: null,
  Inbound: null,
  Listen: null,
  Outbound: null,
  Remove: null,
  Share: null,
};

export const MEDIA_SUPPORT = {
  getDisplayMedia: "getDisplayMedia",
  shimDisplayMedia: "shimGetUserMedia",
};

export const WORD_REGEX = /[a-zA-Z0-9_]+/;

export const SMS_CREATION_TIMEOUT = 20;

export const EMAIL_TYPE = {
  REPLY: "reply",
  FORWARD: "forward",
};

export const WEBHOOK_TYPE = {
  EMAIL: "email",
  SMS: "sms",
};

export const DEFAULT = "default";
export const OFFLINE = "offline";
export const ROUTABLE = "routable";
